import { KeyValue } from '../../../constants';

export const OptionalServices: KeyValue = {
  7: 'Infant seat',
  8: 'Child seat',
  9: 'Booter seat',
  13: 'Navigator',
};

export const OptionalServicesOT: KeyValue = {
  '0001': '영아 시트',
  '10BB': '영아 시트',
  '0002': '유아 시트',
  '10CH': '유아 시트',
  '0003': '아동 시트',
  '10JH': '아동 시트',
};

export const SupplierOptions: KeyValue = {
  ZE: '허츠',
  ZR: '달러',
  OT: '온라인트래블',
  RC: '리치',
  TM: '투몬',
};
